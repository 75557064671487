import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import style from './Questions.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TicketContext } from '../../contexts/TicketContext';

import { StepsUpdateContext } from '../../components/Steps/StepsContext';
import { LoadingImages } from '../../components/LoadingImages/LoadingImages';
import BackButton from '../../components/BackButton';
import { isSalesforceMessage } from '../../helpers/Message';
import { Ticket } from '../../interfaces/Ticket';

import { usePost } from '../../helpers/hooks/usePost';
import { isInteger } from '../../helpers/isInteger';
import { SupportedErrorTypes } from '../../services/ErrorService';
import usePageTitle from '../../services/hooks/usePageTitle';
import QuestionsController from './QuestionsController';
import QuestionsService from '../../services/QuestionsService';

const Questions: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket, fetchTicket } = useContext(TicketContext);
  const { completeCurrentStep, navigateToNextStep, navigateToError } = useContext(StepsUpdateContext);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  const url = QuestionsController.getQuestionsUrl(stylesheet, ticket);
  const { response: { pending }, doRequest } = usePost();

  usePageTitle(t('pageTitle:questions'));

  const scrollTop = useCallback(() => window.scrollTo({ behavior: 'smooth', top: 0 }), []);

  useEffect(() => {
    if (url === '') {
      completeCurrentStep();
      navigateToNextStep();
    }
  }, [stylesheet, ticket, url, completeCurrentStep, navigateToNextStep]);


  useEffect(() => {
    scrollTop();
  }, [scrollTop]);

  const initializeIframe = useCallback((height: number) => {
    const HEIGHT_BUFFER = 30;

    if (isLoading && height > 10) {
      setLoading(false);
    }
    setIframeHeight(height + HEIGHT_BUFFER);
  }, [isLoading]);

  const doQuestionaireCompletion = useCallback((ticketInfo: Ticket) => {
    const questionsService = new QuestionsService();

    const request = Promise.resolve()
      .then(() => questionsService.completeQuestionnaire(ticketInfo.workOrder.appointment.id))
      .then(() => fetchTicket())
      .then((updatedTicket) => {
        if (!updatedTicket) {
          return;
        }

        completeCurrentStep();

        if (!updatedTicket.workOrder.appointment.isSchedulable) {
          navigateToError(SupportedErrorTypes.appointmentNotPossible);
        } else {
          navigateToNextStep();
        }
      });
    doRequest(request);
  }, [completeCurrentStep, doRequest, fetchTicket, navigateToError, navigateToNextStep]);

  const messageListener = useCallback(({ data }: { data: unknown }) => {
    // backwards compatibility remove once lightning components are enabled completely
    if (data === 'QUESTIONNAIRE_COMPLETED' && ticket) {
      doQuestionaireCompletion(ticket);
    } else if (isInteger(data)) {
      initializeIframe(data);
    // lightning components if blocks from this point on, only delete until this comment block
    } else if (isSalesforceMessage(data)) {
      if (data.payload === 'QUESTIONNAIRE_COMPLETED' && ticket) {
        doQuestionaireCompletion(ticket);
      } else if (data.payload === 'QUESTIONNAIRE_SCROLL_TOP') {
        scrollTop();
      } else if (isInteger(data.payload)){
        initializeIframe(data.payload);
      }
    }
  }, [doQuestionaireCompletion, scrollTop, initializeIframe, ticket]);

  useEffect(() => {
    const unsubscribe = QuestionsController.subscribeWindowMessages(messageListener);

    return unsubscribe;
  }, [messageListener]);

  if (!stylesheet) {
    return null;
  }

  const styles = classNames(style.ticketQuestions, {
    [style.justifyCenter]: (pending || isLoading)
  });

  return (
    <div className={styles}>
      {(pending || !isLoading) && <BackButton text={t('question:goBack')} />}
      {(pending || isLoading) && (
        <div className={style.imagesContainer}>
          <LoadingImages
            items={isLoading
              ? [{ image: 'loadingQuestions', text: t('question:loadingQuestions'), ariaLabel: t('question:loadingQuestions') }]
              : pending
                ? [{ image: 'questionsSaved', text: t('question:savingAnswers'), ariaLabel: t('question:savingAnswers') }]
                : []
            }
          />
        </div>
      )}
      <iframe title="questions" aria-label={t('stepsBar:questions')} src={url} height={iframeHeight} />
    </div>
  );
};

export default Questions;
