import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';

import { Appointment } from '../../../interfaces/Appointment';
import { Urls } from '../../../interfaces/Calendar';
import styles from './Calendar.module.scss';
import Button from '../../../components/Button';

import getUrls from '../../../services/CalendarService';
import DateTime from '../../../helpers/DateTime';
import { sendCalendarOpened, sendCalendarSelected } from '../../../services/Analytics';
import Backdrop from '../../../components/Backdrop/Backdrop';
import { CloseButton } from '../../../components/CloseButton/CloseButton';

interface CalendarProps {
  text: string;
  appointment: Appointment;
  partnerName: string;
}

const CalendarComponent: FC<CalendarProps> = ({ text, appointment, partnerName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen((previousValue) => !previousValue);
    sendCalendarOpened();
  }, []);

  const urls: Urls = useMemo(() => {
    const startTime = DateTime.roundToNearestMinutes(appointment.arrivalWindowStart || DateTime.today());
    const endTime = DateTime.roundToNearestMinutes(appointment.arrivalWindowEnd || DateTime.today());

    return getUrls({
      start: startTime.toISOString(),
      finish: endTime.toISOString(),
      title: partnerName + ' monteur komt langs',
      address: 'Thuis',
      description: ''
    });
  }, [appointment, partnerName]);

  const dropDownContent = useMemo<{ url: keyof Urls; label: string }[]>(() => [
    { url: 'google', label: 'Google' },
    { url: 'yahoo', label: 'Yahoo' },
    { url: 'ics', label: 'Ical' },
    { url: 'outlook', label: 'Outlook' },
  ], []);

  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <div className={styles.calendarConfirm}>
      <Backdrop onClick={onClose} visible={isOpen} />
      <Button text={text} onClick={onClick} size="small" />
      {isOpen && (
        <ul id="calendar" className={styles.dropdownContent}>
          <CloseButton onClick={onClose} alignment="topRight" />
          {!!dropDownContent.length && dropDownContent.map(({ url, label }) => (
            <li aria-label={label} key={label}>
              <Button size="small">
                <a
                  href={urls[url]}
                  onClick={() => sendCalendarSelected(url)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label}
                </a>
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CalendarComponent;
